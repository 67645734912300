import * as React from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { getDatesSummary, getPassengersCount, getPassengersSummary, getRouteType } from '../../utils';
import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import { RouteType } from '../../types';
import { arrowIcon, owIcon } from '../../Icons';
var Summary = function (_a) {
    var _b;
    var className = _a.className, parameters = _a.parameters, onClick = _a.onClick, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, renderPromoCode = _a.renderPromoCode;
    var css = useTheme('QuickSearchForm').Summary;
    var t = useTranslation('QuickSearchForm').t;
    var routeType = getRouteType(parameters.segments);
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var displayIataCodes = React.useMemo(function () {
        var departureCityName = parameters.segments[0].departure.city.name;
        var arrivalCityName = parameters.segments[0].arrival.city.name;
        if (!departureCityName.length || !arrivalCityName.length) {
            return true;
        }
        var allowedRouteLength = departureCityName.length + arrivalCityName.length >= 15;
        if (isMobile) {
            return allowedRouteLength || parameters.segments.length >= 2;
        }
        else if (routeType === RouteType.ComplexRoute) {
            return allowedRouteLength || parameters.segments.length > 2;
        }
        return allowedRouteLength;
    }, [parameters.segments, isMobile, routeType]);
    var datesSummary = React.useMemo(function () {
        return getDatesSummary(parameters.segments);
    }, [parameters.segments]);
    var passengersSummary = React.useMemo(function () {
        return getPassengersSummary(parameters.passengers).join(', ');
    }, [parameters.passengers]);
    return (React.createElement("div", { className: cn(css.summary, className), onClick: onClick },
        React.createElement("span", { className: css.route },
            (routeType === RouteType.OneWay || routeType === RouteType.RoundTrip) && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                    displayIataCodes
                        ? parameters.segments[0].departure.iata
                        : parameters.segments[0].departure.city.name,
                    React.createElement("span", { className: css.route__separator }, "\u2014"),
                    displayIataCodes
                        ? parameters.segments[0].arrival.iata
                        : parameters.segments[0].arrival.city.name),
                React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
                    parameters.segments[0].departure.city.name
                        ? parameters.segments[0].departure.city.name
                        : parameters.segments[0].departure.name,
                    React.createElement("span", { className: css.route__separator }, "\u2014"),
                    parameters.segments[0].arrival.city.name
                        ? parameters.segments[0].arrival.city.name
                        : parameters.segments[0].arrival.name))),
            routeType === RouteType.ComplexRoute &&
                parameters.segments.map(function (segment, index) { return (React.createElement(React.Fragment, { key: index },
                    displayIataCodes ? segment.departure.iata : segment.departure.city.name,
                    React.createElement("span", { className: css.route__icon }, owIcon),
                    displayIataCodes
                        ? index === parameters.segments.length - 1 && segment.arrival.iata
                        : index === parameters.segments.length - 1 && segment.arrival.city.name)); })),
        React.createElement("span", { className: css.dates }, datesSummary.map(function (date) { return (React.createElement("span", { key: date }, date)); })),
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement("span", { className: css.passengers }, passengersSummary)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("span", { className: css.passengers },
                getPassengersCount(parameters.passengers),
                ' ',
                parameters.passengers.length === 1 && !parameters.passengers[0].extendedPassengerType
                    ? t(parameters.passengers[0].passengerType, { count: parameters.passengers[0].count })
                    : t('passenger', { count: getPassengersCount(parameters.passengers) }))),
        React.createElement("span", { className: css.trigger },
            React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
                React.createElement("span", null, t('Modify search'))),
            React.createElement("span", { className: cn(css.trigger__arrow, (_b = {},
                    _b[css.trigger__arrow_reversed] = isOpen,
                    _b)) }, arrowIcon)),
        renderPromoCode && renderPromoCode()));
};
export default Summary;
